import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Reserva, Local, Bloco, Unidade, Usuario, TIPO_USUARIO } from 'app/models';
import { BaseModal } from 'app/componentes-utils/modal';
import { AuthService, MessageService } from 'app/core';
import { LocalHttpService } from 'app/home/local';
import { ReservaAreaHttpService } from '../services';
import { DatePipe } from '@angular/common';
import { BlocosHttpService, BlocosPesquisaModalComponent, UnidadesPesquisaModalComponent } from 'app/home/blocos';
import { UnidadesHttpService } from 'app/home/blocos/unidades/services/unidades-http.service';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { isNull } from 'util';
import { AcessosPainelHttpService } from 'app/home/grupos-de-usuarios/acessos-painel/services';
import { AutorizacaoEnum } from 'app/models/enums/autorizacao-enum';

declare var jQuery: any;

@Component({
  selector: 'cadastro-reserva-modal',
  templateUrl: './cadastro-reserva-modal.component.html',
  styleUrls: ['./cadastro-reserva-modal.component.css'],
  providers: [ DatePipe ]
})
export class CadastroReservaModalComponent implements OnInit, BaseModal {

  @ViewChild('inputBloco') inputBloco: ElementRef;
  @ViewChild('blocosPesquisaModal') blocosPesquisaModal: BlocosPesquisaModalComponent;
  @ViewChild('inputUnidade') inputUnidade: ElementRef;
  @ViewChild('unidadesPesquisaModal') unidadesPesquisaModal: UnidadesPesquisaModalComponent;

  @Input() idModal: string;
  @Input() idLocal: number;
  @Input() idReserva: number;
  @Input() dataReserva: Date;
  @Output() onChange: EventEmitter<Reserva> = new EventEmitter();

  public locais: Local[] = [];
  public titulo: string;
  public disponibilidades: any[] = [];
  public reserva: Reserva;
  public id_usuario: number;
  public isAdmin: boolean;

  public blocos: Bloco[] = [];
  public bloco: Bloco;
  public id_bloco: number;
  public unidades: Unidade[] = [];
  public unidade: Unidade;
  public local: Local;

  public currentTab: number = 1;
  public politica: string = '';
  public aceitaPolitica: boolean = false;
  public aceitaResumo: boolean = false;
  private usuario: Usuario;
  public id_unidade_condomino: number;
  private hoje: Date;
  public isIsencaoHabilitada = false;
  public lotacao: number = 0;

  public resumo: string = '';

  public editorConfig: any = {
    editable: false,
    spellcheck: true,
    height: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: '...',
    imageEndPoint: '',
    toolbar: []
  };

  constructor(private authService: AuthService,
      private messageService: MessageService,
      private localHttpService: LocalHttpService,
      private reservaAreaHttpService: ReservaAreaHttpService,
      private blocosHttpService: BlocosHttpService,
      private unidadesHttpService: UnidadesHttpService,
      private acessosPainelHttpService: AcessosPainelHttpService,
      public datePipe: DatePipe,) {
    this.reserva = new Reserva();
  }

  ngOnInit() {
    if (!this.idModal) {
      this.idModal = 'reservaCadastroModal';
    }
    this.usuario = this.authService.getCurrentUser();
    this.isAdmin = [1,2].includes(this.authService.getTipoCurrentUser());
    this.id_usuario = this.usuario.id;
    this.getAllLocalByCondominioAtivo();
    
    if (this.usuario.tipo !== 3) {
      this.getBlocosByCondominio();
    }
    
    this.local = new Local();
    this.local.msgCancelamento = '';
    if (this.usuario.tipo === 3) {
      this.loadUsuario();
    }
    this.hoje = new Date();
    this.hoje = new Date(this.hoje.getFullYear(), this.hoje.getMonth(), this.hoje.getDate());
    this.loadIsIsencaoHabilitada();
  }

  private loadIsIsencaoHabilitada(): void {
    const currentCondominio = this.authService.getCurrentCondominio();
    if (currentCondominio) {
      this.acessosPainelHttpService.getAllByGrupoDeUsuario(currentCondominio.id_grupo_de_usuario).subscribe(acessosPainel => {
        this.isIsencaoHabilitada = !!acessosPainel.autorizacoes.filter(autorizacao => autorizacao.codigo === AutorizacaoEnum.AUTORIZACAO_ISENCAO);
      });
    }
  }

  /**
   * Carrega os dados do usuário e valida perfil para permitir reservas
   * @author Marcos Frare
   * @since 24/03/2020
   */
  private loadUsuario(): void {
    this.unidadesHttpService.getUnidadesByUsuario(this.authService.getIdCurrentCondominio(), this.id_usuario).subscribe(
      response => {
        this.unidades = response
        if (this.unidades.length === 1) {
          this.id_unidade_condomino = this.unidades[0].id;
          this.reserva.id_unidade = this.id_unidade_condomino;
        }
      },
      error => this.messageService.error('Erro ao carregar unidades do usuário', 'Erro!', error)
    );
  }

  /**
   * Atualiza valor total da reserva
   * @author Marcos Frare
   * @since 19/03/2020
   */
  private loadValor(): void {
    this.reserva.valor_reserva = 0;
    // Se não for isenta
    if (this.reserva.tipo != 'ISENTA') {
      // Verificar se selecionou a disponibilidade do local, para carregar o valor da disponibilidade
      const selectDisponibilidade = this.disponibilidades.filter(ds => ds.id == this.reserva.id_local_disponibilidade);
      const hasDisponibilidade = selectDisponibilidade && selectDisponibilidade.length;
      this.reserva.disponibilidade = hasDisponibilidade? selectDisponibilidade[0] : {};

      // Valor da reserva
      if (hasDisponibilidade && selectDisponibilidade[0].valor) {
        this.reserva.valor_reserva = parseFloat(selectDisponibilidade[0].valor);
      } else if (this.local && this.local.localConfiguracao && this.local.localConfiguracao.possui_cobranca) {
        this.reserva.valor_reserva += parseFloat(this.local.localConfiguracao.valor_reserva);
      }
      // Valor da taxa de limpeza
      if (hasDisponibilidade && selectDisponibilidade[0].taxa_limpeza && selectDisponibilidade[0].taxa_limpeza > 0) {
        this.reserva.valor_reserva += parseFloat(selectDisponibilidade[0].taxa_limpeza);
      } else if (this.local && this.local.localConfiguracao && this.local.localConfiguracao.taxa_limpeza) {
        this.reserva.valor_reserva += parseFloat(this.local.localConfiguracao.valor_taxa_limpeza);
      }
      // ítens inclusos com valores
      if (this.local && this.local.localUtensilios && Array.isArray(this.local.localUtensilios)) {
        this.local.localUtensilios.forEach(utensilio => {
          if (utensilio.tipo === 1) {
            this.reserva.valor_reserva += parseFloat(utensilio.valor_reserva);
          }
          if (utensilio.tipo === 2 && utensilio.selected) {
            this.reserva.valor_reserva += parseFloat(utensilio.valor_reserva);
          }
        });
      }
    }
  }

  /**
   * No caso de ser um condômino, seleciona uma das unidades ao qual o usuário é sacado
   * @author Marcos Frare
   * @since 25/03/2020
   */
  public changeUnidadeCondomino(): void {
    this.reserva.id_unidade = this.id_unidade_condomino;
  }

  /**
   * Ação ao alterar campo de local
   * @author Marcos Frare
   * @since 19/03/2020
   */
  public onChangeLocal(): void {
    this.setLotacao();
    this.getDisponibilidade();
    this.politica = '';
    // Carrega configurações do local, utensílios e políticas
    this.localHttpService.getDadosByReserva(this.reserva, this.isAdmin).subscribe(
      response => {
        this.local = response;
        if (response.localPolitica && response.localPolitica.politica) {
          this.politica = response.localPolitica.politica;
        }
        this.loadValor();
      },
      error => this.messageService.error('Erro ao carregar dados do local.', 'Erro', error)
    );
  }

  private setLotacao() : void {
    let local = this.locais.filter(l => l.id == this.reserva.id_local);
    this.lotacao = local[0].lotacao || 0;
  }

  /**
   * Ação de alteração da disponibilidade do local
   * @author Marcos Frare
   * @since 02/06/2021
   */
  public onChangeDisponibilidade(): void {
    this.loadValor();
  }

  /**
   * Busca disponibilidade para a reserva
   * @author Marcos Frare
   * @since 18/03/2020
   */
  private getDisponibilidade(): void {
    this.disponibilidades = [];
    if (this.reserva.id_local && this.reserva.data_reserva) {
      this.localHttpService.getDisponibilidadeReserva(this.reserva.id_local, this.datePipe.transform(this.reserva.data_reserva, 'yyyy-MM-dd'), this.reserva.id).subscribe(
        response => this.disponibilidades = response,
        error => this.messageService.error('Erro ao carregar periodos para reserva.', 'Erro', error)
      );
    }
  }

  /**
   * Validações da reserva no caso de edição
   * @author Marcos Frare
   * @since 25/02/2020
   */
  private validaReserva(): boolean {
    // Se o usuário for do tipo condômino, verifica se as unidades do condômino coincidem com a unidade da reserva
    let dono = false;
    if (this.usuario.tipo === 3) {
      for (let unid of this.unidades) {
        if (this.reserva.id_unidade === unid.id) {
          dono = true;
        }
      }
      if (!dono) {
        this.messageService.info('Você não tem permissão para alteração da reserva!', 'Informação!');
        return false;
      }
    }
    // Verifica se a data da reserva é anterior a data atual, para não permitir alteração por parte do condômino
    let dataRes = new Date(this.reserva.data_reserva);
    if (this.usuario.tipo === 3 && (dataRes < this.hoje) && this.reserva.validacao) {
      this.reserva.validacao = false;
      this.reserva.msg = '* Expirado prazo para alteração da reserva!';
    }
    return true;
  }

  /**
   * Carrega dados da reserva
   * @author Marcos Frare
   * @since 17/03/2020
   */
  private loadReserva(id: number): void {
    this.unidade = null;
    this.bloco = null;
    this.reservaAreaHttpService.get(id).subscribe(
      response => {
        this.reserva = response;
        this.unidade = response.unidade;
        this.bloco = response.bloco;
        this.id_unidade_condomino = this.reserva.id_unidade;
        if (this.reserva.id) {
          this.aceitaPolitica = true;
          this.aceitaResumo = true;
        }
        if (this.validaReserva()) {
          this.onChangeLocal();
          jQuery(`#${this.idModal}`).modal('show');
        } else {
          this.close();
        }
      },
      error => this.messageService.error('Erro ao carregar a reserva!!', 'Erro', error)
    );
  }

  /**
   * Busca os locais do condomínio
   * @author Marcos Frare
   * @since 16/03/2020
   */
  private getAllLocalByCondominioAtivo(): void {
    this.localHttpService.getAllByFiltro({id_condominio: this.authService.getIdCurrentCondominio(), ativo: 1}).subscribe(
      response => {
        this.locais = response;
      },
      error => this.messageService.error('Erro ao carregar os locais do condomínio.', 'Erro', error)
    );
  }

  /**
   * Persiste os dados da reserva
   * @author Marcos Frare
   * @since 17/03/2020
   */
  public reservar(): void {
    const btnReservar = jQuery('#btnReservar');
    btnReservar.button('loading');
    this.reserva.local = this.local;
    this.reservaAreaHttpService.salvaReserva(this.reserva).subscribe(
      response => {
        if (response.success) {
          this.onChange.emit(response);
          this.close();
          btnReservar.button('reset');
          this.messageService.success('', 'Local reservado com sucesso!');
        } else {
          this.messageService.warning(response.msg, 'Atenção!');
        }
      },
      error => {
        this.messageService.error('Erro ao completar reserva. Tente novamente.', 'Erro', error);
        btnReservar.button('reset');
      }
    );
  }

  /**
   * Cancela a reserva
   * @author Marcos Frare
   * @since 18/03/2020
   */
  public cancelar(): void {
    const btnCancelar = jQuery('#btnCancelar');
    if (confirm(`Tem certeza que deseja ${this.usuario.tipo == 3 ? 'cancelar' : 'excluir'} a reserva?`)) {
      if (this.reserva.id && this.reserva.id !== null) {
        btnCancelar.button('loading');
        let fn = this.usuario.tipo == 3 ? this.reservaAreaHttpService.cancelaReserva(this.reserva.id) : this.reservaAreaHttpService.excluirReserva(this.reserva.id)
        fn.subscribe(
          response => {
            btnCancelar.button('reset');
            if (response.success) {
              this.onChange.emit(null);
              this.close();
              this.messageService.success('', 'Reserva cancelada com sucesso!');
            } else {
              this.messageService.warning(response.msg, 'Atenção!');
            }
          },
          error => {
            this.messageService.error('Erro ao completar reserva. Tente novamente.', 'Erro', error);
            btnCancelar.button('reset');
          }
        );
      }
    }
  }

  /**
   * Validação das TABS
   * @author Marcos Frare
   * @since 20/03/2020
   */
  private validateTab(): boolean {
    let msg = '';
    let btnProximo = jQuery('#btnProximo');
    btnProximo.button('loading');
    if (this.currentTab === 1) {
      // Se o local tiver alguma restrição, não validar e mostrar mensagem
      if (this.local.restricao && this.local.restricao !== '') {
        msg += this.local.restricao;
      }

      if (this.reserva.tipo == 'NORMAL' && (!this.reserva.id_unidade || isNull(this.reserva.id_unidade))) {
        msg += 'Necessário informar a <b>unidade</b> para efetuar a reserva!<br/>';
      }
      if (!this.reserva.id_local || isNull(this.reserva.id_local)) {
        msg += 'Necessário informar o <b>local</b> para efetuar a reserva!<br/>';
      }
      if (!this.reserva.id_local_disponibilidade || isNull(this.reserva.id_local_disponibilidade)) {
        msg += 'Necessário informar o <b>período</b> para efetuar a reserva!<br/>';
      }
    }

    // Carrega o resumo da reserva
    if (this.currentTab === 3) {
      if (!this.aceitaPolitica) {
        msg += 'Para prosseguir, precisa aceitar os termos da <b>política</b> de utilização!</br>';
      }

      this.resumo = 'Processando...';

      this.reserva.local = this.local;
      this.reservaAreaHttpService.montaResumo(this.reserva).subscribe(
        response => {
          btnProximo.button('reset');
          this.resumo = response.resumo;
        },
        error => {
          btnProximo.button('reset');
          this.messageService.error('Erro ao carregar resumo da reserva!', 'Erro!', error);
        }
      );
    } else {
      btnProximo.button('reset');
    }
    
    // Se tiver alguma mensagem, trava o processo
    if (msg !== '') {
      this.messageService.warning(msg, 'Atenção!');
      return false;
    }
    return true;
  }

  public validateAndNextTabAndVerifyMultLocals = () => {
    if (this.local.condominioConfiguracaoReserva.multipla_reserva_data == 0) {
      let params : any = {
        data_reserva: this.datePipe.transform(this.reserva.data_reserva, 'yyyy-MM-dd'),
        id_unidade: this.reserva.id_unidade ? this.reserva.id_unidade: undefined
      };
      params.id_local_excecao = this.local.id;
      this.reservaAreaHttpService.getReservas(params).subscribe(reservaExistente => {
        if (reservaExistente && reservaExistente.length > 0) {
          this.messageService.warning('Unidade já possui reservas de outro(s) local(is) nesta data', 'Atenção!');
        } else {
          if (this.validateTab()) {
            this.currentTab++;
          }
        }
      });
    } else {
      if (this.validateTab()) {
        this.currentTab++;
      }
    }
  }

  /**
   * Faz validação, e passa para o proximo tab
   * @autor Marcos Frare
   * @since 20/03/2020
   */
  public nextTab(): void {
    let tipoUsuario = this.authService.getTipoCurrentUser();
    if (this.currentTab === 1 && ![1,2].includes(tipoUsuario)) {
      this.validateAndNextTabAndVerifyMultLocals();
    } else {
      if (this.validateTab()) {
        this.currentTab++;
      }
    }
  }

  /**
   * Volta uma tab
   * @autor Marcos Frare
   * @since 20/03/2020
   */
  public backTab(): void {
    this.currentTab--;
  }

  /**
   * Navegação das abas
   * @param id Número da aba de navegação
   * @author Marcos Frare
   * @since 23/03/2020
   */
  public loadTab(id: number): void {
    if (id > this.currentTab) {
      while (this.currentTab < id && this.validateTab()) {
        this.currentTab++;
      }
    } else {
      this.currentTab = id;
    }
  }

  /**
   * Abre modal
   */
  open() {
    this.currentTab = 1;
    this.aceitaPolitica = false;
    this.aceitaResumo = false;
    // this.msgCancelamento = '* O cancelamento desta reserva gera multa de R$ 15,00';
    this.local.msgCancelamento = '';
    this.reserva = new Reserva();
    this.reserva.data_reserva = this.dataReserva;
    this.reserva.id = this.idReserva;
    this.reserva.id_usuario = this.id_usuario;
    this.reserva.id_local = this.idLocal;
    this.reserva.id_unidade = this.id_unidade_condomino;
    // Se tiver ID na Reserva, carrega a reserva
    // this.getBlocosByCondominio();
    if (this.reserva && this.reserva.id) {
      this.loadReserva(this.reserva.id);
    } else {
      jQuery(`#${this.idModal}`).modal('show');
    }
  }

  /**
   * Fecha modal
   */
  close() {
    this.local.msgCancelamento = '';
    this.currentTab = 1;
    this.reserva = new Reserva();
    this.bloco = null;
    this.unidade = null;
    jQuery(`#${this.idModal}`).modal('hide');
  }

  /**
   * Carrega os blocos do Condomínio
   */
  private getBlocosByCondominio(): void {
    this.blocos = [];
    this.blocosHttpService.getAllByCondominio(this.authService.getIdCurrentCondominio()).subscribe(
      entities => {
        this.blocos = entities;
        if (entities.length === 1) this.setBloco(entities[0])
      },
      error => this.messageService.error('Erro ao carregar os blocos do condomínio. Tente novamente', 'Erro', error)
    );
  }

  /**
   * Atribui valor do bloco
   * @param blocoSelecionado Bloco selecionado
   */
  private setBloco(blocoSelecionado: Bloco): void {
    this.bloco = blocoSelecionado;
    this.id_bloco = this.bloco.id;
    this.getAllUnidadesByBloco(this.bloco.id);
    this.onCleanUnidade(this.inputUnidade.nativeElement);
  }

  /**
   * Busca todas as unidade de um bloco
   * @param idBloco 
   */
  public getAllUnidadesByBloco(idBloco: number): void {
    this.unidades = [];
    this.unidadesHttpService.getAllByBloco(idBloco).subscribe(
      entities => {
        for (const unidade of entities) {
          if (unidade.nome) {
            this.unidades.push(unidade);
          }
        }
      },
      error => this.messageService.error('Erro ao carregar as unidades do bloco. Tente novamente', 'Erro', error)
    );
  }

  onSelectBloco(tm: TypeaheadMatch): void {
    this.setBloco(tm.item as Bloco);
  }

  /**
   * Limpa campo de Unidades
   * @param input Objeto do elemento
   */
  public onCleanUnidade(input: any): void {
    this.unidade = null;
    this.reserva.id_unidade = null;
    input.value = '';
    input.focus();
  }

  private setUnidade(unidadeSelecionada: Unidade): void {
    this.unidade = unidadeSelecionada;
    this.reserva.id_unidade = this.unidade.id;
  }

  public onSelectUnidade(tm: TypeaheadMatch): void {
    this.setUnidade(tm.item as Unidade);
  }

  openBlocosPequisaModal() {
    this.blocosPesquisaModal.open();
  }

  onSelectBlocosPesquisaModal(bloco: Bloco) {
    if (bloco) {
      this.setBloco(bloco)
    }
    jQuery('#bloco').focus();
  }

  public openUnidadesPequisaModal(): void {
    this.unidadesPesquisaModal.open();
  }

  public onSelectUnidadesPesquisaModal(unidade: Unidade): void {
    if (unidade) { this.setUnidade(unidade); }
    jQuery('#unidade').focus();
  }

  public onCleanBloco(input: any): void {
    this.onCleanUnidade(this.inputUnidade.nativeElement);
    this.id_bloco = null;
    this.bloco = null;
    input.value = '';
    input.focus();
  }

}